<template>
    <div v-loading.fullscreen.lock="loading">
        <vacation-count-search-bar-component
            @search="get_vacation_count"
        ></vacation-count-search-bar-component>
        <!-- <common-table-component
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            table_height="650px"
        ></common-table-component> -->
        <el-table
            :header-cell-style="{fontSize:'14px'}"  
            :data="ftable_data"
            height="650px"
            border
            stripe
            highlight-current-row
            size="medium"
            style="width: 100%"
        >
            <el-table-column align="center" type="index" fixed></el-table-column>
            <el-table-column align="center" prop="name" label="姓名" minWidth="70px" fixed></el-table-column>
            <el-table-column align="center" label="年假(统计全年天数)">
                <el-table-column align="center" label="可休" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#2828FF">{{ scope.row['vacation']['all_paid_vacation'] }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="已休" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#FF0000">{{ scope.row['vacation']['use_paid_vacation'] }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="剩余" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#00BB00">{{ scope.row['vacation'] | surplus_paid_vacation }}</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column align="center" label="调休">
                <el-table-column align="center" label="可休" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#2828FF">{{ scope.row['vacation'] | all_over_time_day}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="已休" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#FF0000">{{ scope.row['vacation']['use_over_time']}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="过期" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#E800E8">{{ scope.row['vacation'] | del_over_time_day}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="剩余" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#00BB00">{{ scope.row['vacation'] | surplus_over_time}}</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column align="center" label="育儿假(统计全年天数)">
                <el-table-column align="center" label="可休" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#2828FF">{{ scope.row['vacation']['all_child_vacation'] }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="已休" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#FF0000">{{ scope.row['vacation']['use_child_vacation'] }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="剩余" minWidth="60px">
                    <template slot-scope="scope">
                        <span style="color:#00BB00">{{ scope.row['vacation'] | surplus_child_vacation }}</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column align="center" prop="nj" label="年假(天)" minWidth="60px"></el-table-column>
            <el-table-column align="center" prop="tx" label="调休(天)" minWidth="60px"></el-table-column>
            <el-table-column align="center" prop="yej" label="育儿假(天)" minWidth="70px"></el-table-column>
            <el-table-column align="center" prop="sj" label="事假(天)" minWidth="60px"></el-table-column>
            <el-table-column align="center" prop="bj" label="病假(天)" minWidth="60px"></el-table-column>
            <el-table-column align="center" prop="hj" label="婚假(天)" minWidth="60px"></el-table-column>
            <el-table-column align="center" prop="cj" label="产假(天)" minWidth="60px"></el-table-column>
            <el-table-column align="center" prop="pcj" label="陪产假(天)" minWidth="70px"></el-table-column>
            <el-table-column align="center" prop="sangj" label="丧假(天)" minWidth="60px"></el-table-column>
            <el-table-column align="center" prop="qt" label="其它(天)" minWidth="60px"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import { vacation_count_request} from '@/network/hrm/vacation.js'

import VacationCountSearchBarComponent from '@/components/hrm/vacation/VacationCountSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name:'VacationCountView',
    data(){
        return {
            loading: false,
            cond: {
                staff_id: '',
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '姓名',
                    minWidth: '70px',
                },
                {
                    prop: 'nj',
                    label: '年假',
                    minWidth: '60px'
                },
                {
                    prop: 'sj',
                    label: '事假',
                    minWidth: '60px'
                },
                {
                    prop: 'bj',
                    label: '病假',
                    minWidth: '60px'
                },
                {
                    prop: 'tx',
                    label: '调休',
                    minWidth: '60px'
                },
                {
                    prop: 'hj',
                    label: '婚假',
                    minWidth: '60px'
                },
                {
                    prop: 'cj',
                    label: '产假',
                    minWidth: '60px'
                },
                {
                    prop: 'pcj',
                    label: '陪产假',
                    minWidth: '70px'
                },
                {
                    prop: 'yej',
                    label: '育儿假',
                    minWidth: '70px'
                },
                {
                    prop: 'sangj',
                    label: '丧假',
                    minWidth: '60px'
                },
                {
                    prop: 'qt',
                    label: '其它',
                    minWidth: '60px'
                }
            ]
        }
    },
    computed:{},
    methods:{
        get_vacation_count(param = {}) {
            this.loading = true
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.start_time = param.start_time ?? this.cond.start_time
            this.cond.end_time = param.end_time ?? this.cond.end_time

            vacation_count_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{
        all_over_time_day(v) {
            return Math.trunc(v['all_over_time']/4)/2
        },
        del_over_time_day(v) {
            return Math.trunc(v['del_over_time']/4)/2
        },
        surplus_paid_vacation(v) {
            const paid_vacation = v['all_paid_vacation'] - v['use_paid_vacation']
            return Math.trunc(paid_vacation*2)/2
        },
        surplus_child_vacation(v) {
            const child_vacation = v['all_child_vacation'] - v['use_child_vacation']
            let res = Math.trunc(child_vacation*2)/2
            res = res<0 ? 0 : res
            return res
        },
        surplus_over_time(v){
            const all_over_time = v['all_over_time']
            const use_over_time = Math.trunc(v['use_over_time']*8)
            const del_over_time = v['del_over_time']
            return Math.trunc((all_over_time - use_over_time - del_over_time)/4)/2
        }
    },
    props:{},
    created(){
        this.get_vacation_count()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        VacationCountSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>